.details{
  background: black;
  color: #fff;
  min-height: 200px;
  /*padding: 20px;*/
}

.page-section h2{
  text-align: center;
}


.application h2{
  text-align: center;
  margin: 0;
}

.chips{
  border: 1px solid;
  border-radius: 3px;
  padding:  5px 10px;
  display: inline-block;
  margin: 5px;
}

.expertize{
  margin: 10px;
}

.header{
  background: black;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-bottom: 3px solid #fff;
}

.header img{
  height: 150px;
}


@media screen and (max-width: 420px)  {
  .header{
    /*justify-content: left;*/
  }
}